<template>
  <div class="main_view">
    <div class="left_view">
<!--      <button v-for="(item,index) in buttons" :key="index"-->
<!--        @click="viewPage(index,item)"-->
<!--        :class="show === index?'active_type':'default_type'">{{item.name}}-->
<!--      </button>-->

      <div class="btn-view">
        <img src="@/assets/fanshelan.png" v-show="showType === 1" class="btn-img" @click="showViewPage1()">
        <img src="@/assets/fanshehui.png" v-show="showType === 2" class="btn-img" @click="showViewPage1()">

        <img src="@/assets/xianluhui.png" v-show="showType === 1" class="btn-img" @click="showViewPage2()">
        <img src="@/assets/xianlulan.png" v-show="showType === 2" class="btn-img" @click="showViewPage2()">
      </div>






    </div>
    <div class="right_view">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageIndex",
  data(){
    return {
      buttons:[
        {name: "反射信号测量", path: "/pageFirst"},
        {name: "线路阻抗测量", path: "/pageSecond"},
      ],
      show: 0,
      active: 0,
      showType: 1
    }
  },
  mounted() {
    this.viewPage(this.active,this.buttons[this.active])
  },
  methods:{
    showViewPage1() {
      if (this.showType === 1) {
        return ;
      }
      this.showType = 1;
      this.$router.push('/pageFirst')
    },
    showViewPage2() {
      if (this.showType === 2) {
        return ;
      }
      this.showType = 2;
      this.$router.push('/pageSecond')
    },

    viewPage(index,item){
      this.show = index;
      this.$router.push(`${item.path}`)
    }
  }
}
</script>

<style scoped>
  .main_view {
    display: flex;
    justify-content: space-around;
    height: 90%;
  }
  .btn-img {
    width: 65%;
    cursor: pointer;
  }
  .btn-img:nth-child(1),.btn-img:nth-child(2) {
    margin-bottom: 25%;
  }


  .left_view {
    /*display: flex;*/
    /*flex-direction:column;*/
    /*justify-content: center;*/
    /*align-items:center;*/
    width: 30%;
    /*background-color: pink;*/
    position: relative;
  }

  .btn-view {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-65%);
    /*background-color: red;*/
    text-align: center;
  }




  .right_view {
    width: 70%;
  }
  .left_view button{
    width: 400px;
    height: 150px;
    margin: 40px;
    font-size: 28px;
    font-weight: bolder;
  }
  .default_type{
    background: #f0f0f0;
    color: #7c7c7c;
  }
  .active_type{
    background: #17a4fc;
    color: #fff;
  }
</style>
