<template>
    <div class="page-view">
        <div id="barDiv" style="width: 100%;height: 80%"></div>
        <!--    <div>-->
        <!--      <router-link replace class="link" active-class="active" :to="{name:'typeOne'}">333</router-link>-->
        <!--    </div>-->
        <!--    <div>-->
        <!--      <router-link replace class="link" active-class="active" :to="{name:'typeTwo'}">444</router-link>-->
        <!--    </div>-->

        <!--    <router-view></router-view>-->
    </div>
</template>

<script>
    export default {
        name: "pageFirst",
        data() {
            return {
                timer: null,
                list: [23, 60, 20, 36, 23, 45, 20, 36, 23, 45, 23, 45, -20, 36, 23, 60, 20, 36, 23, 45, 20, 36, 23, 45, 23, 45, 20, 36, 23, 60, 20, 36, 23, 45, 20, 36, 23, 45, 23, 45, 20, 36]
            }
        },
        mounted() {
            this.getEcharts(this.list);
            this.timer = setInterval(() => {
                let list = [];
                for (let i = 0; i < this.list.length; i++) {
                    const num = Math.random().toFixed(0) * 40 - 20;
                    list.push(this.list[i] + num);
                }
                this.getEcharts(list);
            }, 6000)
        },
        beforeDestroy() {
            clearInterval(this.timer);
            this.timer = null;
        },
        methods: {
            getEcharts(data) {
                this.barPic = this.$echarts.init(document.getElementById("barDiv"));
                this.barPic.setOption({
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid: {
                        top: '3%',
                        left: '2%',
                        right: '2%',
                        bottom: '2%',
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        data: ['00:00', '00:45', '01:30', '02:15', '03:00', '03:45', '04:30', '05:15', '06:00', '06:45', '07:30', '08:15', '09:00', '00:00', '00:45', '01:30', '02:15', '03:00', '03:45', '04:30', '05:15', '06:00', '06:45', '07:30', '08:15', '09:00', '00:00', '00:45', '01:30', '02:15', '03:00', '03:45', '04:30', '05:15', '06:00', '06:45', '07:30', '08:15', '09:00'],
                        axisLine: {
                            lineStyle: {
                                color: "#fff"
                            }
                        }
                    }],
                    //backgroundColor: '#1a2439',
                    yAxis: [{
                        type: 'value',
                        splitNumber: 4,
                        splitLine: {
                            lineStyle: {
                                type: 'solid',
                                color: '#6c768d'
                            }
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#fff"
                            },
                        },
                        nameTextStyle: {
                            color: "#999"
                        },
                        splitArea: {
                            show: false
                        }
                    }],
                    series: [{
                        name: '数值',
                        type: 'line',
                        data: data,
                        lineStyle: {
                            normal: {
                                width: 4,
                                color: {
                                    type: 'linear',

                                    colorStops: [{
                                        offset: 0,
                                        color: '#708eeb' // 0% 处的颜色
                                    }, {
                                        offset: 1,
                                        color: '#5470c6' // 100% 处的颜色
                                    }],
                                    globalCoord: false // 缺省为 false
                                },
                                shadowColor: 'rgba(72,216,191, 0.3)',
                                shadowBlur: 10,
                                shadowOffsetY: 6
                            }
                        },
                        itemStyle: {
                            normal: {
                                color: '#1a2439',
                                borderWidth: 6,
                                /*shadowColor: 'rgba(54,91,172, 0.3)',
                                shadowBlur: 100,*/
                                borderColor: "#6581d8"
                            }
                        },
                        smooth: true
                    }]
                });


            }
        }

    }
</script>

<style scoped>
    .page-view {
        width: 100%;
        height: 100%;
        /*background-color: pink;*/
    }

    #barDiv {
        /*background-color: pink;*/
        margin-top: 4%;
    }

    .link {
        background-color: white;
    }

    .active {
        background-color: aqua;
    }
</style>
