<template>
    <div class="page-view">

        <div class="second-top">
            <img src="@/assets/dlt1116-bx.png" class="dianlu-img">
        </div>

        <div class="second-bottom">
            <ul class="bottom-list">
                <li class="list-li">
                    <div class="li-title">样本数量H=100</div>
                    <div class="li-title">样本数量H=100</div>
                </li>

                <li class="list-li">
                    <div class="li-title">计算结果/(Ω·km⁻¹)</div>
                    <div class="li-title">实际值/(Ω·km⁻¹)</div>
                    <div class="li-title">测量误差/%</div>
                    <div class="li-title">计算结果/(Ω·km⁻¹)</div>
                    <div class="li-title">实际值/(Ω·km⁻¹)</div>
                    <div class="li-title">测量误差/%</div>
                </li>

                <li class="list-li" v-for="(item,index) in list" :key="index">
                    <div class="li-title">{{item.x1}}</div>
                    <div class="li-title">{{item.x2}}</div>
                    <div class="li-title">{{item.x3}}</div>
                    <div class="li-title">{{item.x4}}</div>
                    <div class="li-title">{{item.x5}}</div>
                    <div class="li-title">{{item.x6}}</div>
                </li>

            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "pageSecond",
        data() {
            return {
                list: [
                    {x1:0.427,x2:0.449,x3:-4.90,x4:0.443,x5:0.427,x6:0.427,},
                    {x1:0.439,x2:0.464,x3:-5.39,x4:0.473,x5:0.464,x6:1.94,},
                    {x1:0.460,x2:0.436,x3:5.50,x4:0.446,x5:0.436,x6:2.29,},
                    {x1:0.451,x2:0.471,x3:-4.25,x4:0.462,x5:0.471,x6:-1.91,},
                    {x1:0.473,x2:0.446,x3:6.05,x4:0.454,x5:0.446,x6:1.79,},
                    {x1:0.469,x2:0.489,x3:-4.09,x4:0.482,x5:0.489,x6:-1.43,},
                    {x1:0.429,x2:0.457,x3:-6.13,x4:0.446,x5:0.457,x6:-2.41,},
                    {x1:0.488,x2:0.489,x3:-4.09,x4:0.482,x5:0.489,x6:-1.43,},
                    {x1:0.415,x2:0.489,x3:-4.09,x4:0.482,x5:0.489,x6:-1.43,},
                    {x1:0.495,x2:0.489,x3:-4.09,x4:0.482,x5:0.489,x6:-1.43,},
                ]
            }
        },

    }
</script>

<style scoped>
    * {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .page-view {
        color: white;
        /*background-color: pink;*/
        width: 100%;
        height: 100%;
    }

    .second-top {
        width: 100%;
        height: 40%;
        margin-top: 3%;
        padding-left: 9%;
    }

    .dianlu-img {
        width: 81%;
        height: 92%;
    }

    .second-bottom {
        width: 100%;
        height: 50%;
        margin-top: 3%;
        padding: 0 2%;
        /*border: 1px solid #fff;*/
    }

    /*表格*/
    .bottom-list {
        width: 96%;
        height: 100%;
        overflow: hidden;
    }
    .list-li {
        display: flex;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        font-size: 20px;
        padding: 6px 0;
    }
    .li-title {
        flex: 1;
        text-align: center;
    }


</style>
