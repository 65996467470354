<template>
  <div id="app">
    <kanbanHead/>
    <pageIndex/>
  </div>
</template>

<script>
import kanbanHead from './components/BoardHeader'
import pageIndex from './components/pageIndex'

export default {
  name: 'App',
  components: {
    kanbanHead,
    pageIndex
  }
}
</script>

<style scoped>
  #app {
    background-image: url("./assets/bg3.png");
    width: 100%;
    height: 1080px;
    background-size: 100%;
    overflow: hidden;
  }
</style>