import VueRouter from 'vue-router'
import pageFirst from '../pages/pageFirst'
import pageSecond from '../pages/pageSecond'
import typeOne from '../pages/pageFirst/types/typeOne'
import typeTwo from '../pages/pageFirst/types/typeTwo'

export default new VueRouter({
    routes:[

        {
            path:'/pageSecond',
            component: pageSecond
        },
        {
            path:'/pageFirst',
            component: pageFirst,
            children: [
                {
                  name:'typeOne',
                  path:'typeOne',
                  component: typeOne,
                },
                {
                    name:'typeTwo',
                    path:'typeTwo',
                    component: typeTwo,
                }
            ]
        }

    ]
})
