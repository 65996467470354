<template>
  <div class="header">
    <img src="../assets/head.png">
    <div class="center_title_view">
      <div>
        东北大学流程工业综合自动化国家重点实验室
      </div>
      <div style="margin-top: 6px">
        工业通信线路关键指标测量平台
      </div>
    </div>
    <span class="right_time_view">{{nowTime}}</span>
  </div>
</template>

<script>
  export default {
    name: 'BoardHeader',
    data() {
      return {
        nowTime: ''
      }
    },
    created() {
      this.nowTime = this.getNowTimeShift();
      setInterval(() => {
        this.nowTime = this.getNowTimeShift();
      }, 1000)
    },
    methods:{
      getNowTimeShift() {
        let date = new Date();
        let Y = (date.getFullYear() + '');
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
        let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours());
        let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
        let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
        return Y + '-' + M + '-' + D + '' + '\xa0\xa0' + h + ':' + m + ':' + s + '  ';
      }
    }
  }
</script>

<style scoped>
  .header {
    width: 100%;
    color: #fff;
    font-size: 23px;
  }
  .header img{
    width: 100%;
    height: 106px;
  }
  .center_title_view{
    position: absolute;
    text-align: center;
    font-weight: bolder;
    top: 24px;
    left: 0;
    right: 0;
  }
  .right_time_view {
    position: absolute;
    right: 210px;
    top: 33px;
    font-size: 21px;
    color: #fff;
  }
</style>
