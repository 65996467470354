import Vue from 'vue'
import App from './App.vue'

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import VueRouter from 'vue-router'
import router from './router'

Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
